import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchReport: (ctx, q) => axiosIns.get(`/api.report${q || ''}`),
  },
}
